@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Customize colors
@import "colors";

// Custom Mixins
@import "mixins";


//
// Textpic
//

.frame-type-textpic {
    overflow: hidden;
}

.textpic-image {
    opacity: 0;

    margin-right: -16px;
    margin-left: -16px;

    .image {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
        position: relative;
        display: flex;
        margin-right: 0;

        .image {
            position: relative;
            width: 100%;
            height: 0;
            overflow: hidden;

            .textpic-small & {
                padding-bottom: 88.888889%;
            }

            .textpic-wide & {
                padding-bottom: 50%;
            }

            .figure-img {
                @include image-object-cover();
            }
        }
    }
}


.textpic-left {
    @include media-breakpoint-up(md) {
        .textpic-image-col {
            order: -1;
        }

        .textpic-image {
            margin-right: 0;
        }

        .textpic-text {
            padding-left: 1rem;
        }
    }

    @include media-breakpoint-up(xl) {

        &.textpic-small {
            .textpic-image {
                margin-right: 0;
                margin-left: calc(((100vw - 300%) / 2) * -1);
            }
        }

        &.textpic-wide {
            .textpic-image {
                margin-right: 0;
                margin-left: calc(((100vw - 150%) / 2) * -1);
            }
        }
    }

    @media (min-width: 1920px) {
        &.textpic-small {
            .textpic-image {
                margin-right: 0;
                margin-left: calc(((1920px - 300%) / 2) * -1);
            }
        }

        &.textpic-wide {
            .textpic-image {
                margin-right: 0;
                margin-left: calc(((1920px - 150%) / 2) * -1);
            }
        }
    }
}

.textpic-right {
    @include media-breakpoint-up(md) {
        .textpic-image {
            margin-left: 0;
        }

        .textpic-text {
            padding-right: 1rem;
        }

        @include media-breakpoint-up(xl) {
            &.textpic-small {
                .textpic-image {
                    margin-left: 0;
                    margin-right: calc(((100vw - 300%) / 2) * -1);
                }
            }

            &.textpic-wide {
                .textpic-image {
                    margin-left: 0;
                    margin-right: calc(((100vw - 150%) / 2) * -1);
                }
            }
        }

        @media (min-width: 1920px) {
            &.textpic-small {
                .textpic-image {
                    margin-left: 0;
                    margin-right: calc(((1920px - 300%) / 2) * -1);
                }
            }

            &.textpic-wide {
                .textpic-image {
                    margin-left: 0;
                    margin-right: calc(((1920px - 150%) / 2) * -1);
                }
            }
        }
    }
}
